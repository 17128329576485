<template>
  <!-- 个人资料 -->
  <div class="contentBox">
    <section>
      <el-card class="box-card">
        <div slot="header">
          <span class="cardTitle">个人资料</span>
        </div>
        <div class="formBox">
          <el-form
            class="comForm"
            ref="comInfo"
            :model="comInfo"
            label-width="80px"
          >
            <el-form-item
              label="头像"
              :rules="[
                {
                  required: true,
                  message: '请选择您的头像',
                  trigger: 'change',
                },
              ]"
            >
              <el-upload
                class="avatar-uploader"
                :action="uploadSrc"
                :headers="headers"
                :data="avatarData"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar mini" />
                <i v-else class="el-icon-plus avatar-uploader-icon mini"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="联系人"
              prop="u_name"
              :rules="[
                {
                  required: true,
                  message: '请输入联系人姓名',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="comInfo.u_name"></el-input>
            </el-form-item>
            <el-form-item
              label="性别"
              prop="u_sex"
              :rules="[
                {
                  required: true,
                  message: '请输入性别',
                  trigger: 'change',
                },
              ]"
            >
              <el-radio v-model="comInfo.u_sex" label="1">男</el-radio>
              <el-radio v-model="comInfo.u_sex" label="2">女</el-radio>
            </el-form-item>
            <el-form-item
              label="出生年月"
              prop="birth"
              :rules="[
                {
                  required: true,
                  message: '请输入出生年月',
                  trigger: 'change',
                },
              ]"
            >
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="comInfo.birth"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="邮箱"
              prop="eamail"
              :rules="[
                {
                  type: 'email',
                  message: '请输入正确的邮箱地址',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input v-model="comInfo.eamail"></el-input>
            </el-form-item>
            <el-form-item label="QQ" prop="qq">
              <el-input
                v-model.number="comInfo.qq"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="固话" prop="fixed_line">
              <el-input
                v-model="comInfo.fixed_line"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="所在城市"
              prop="city"
              :rules="[
                {
                  required: true,
                  message: '请选择所在城市及街道门牌号',
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                size="large"
                :options="userInfoSocial"
                v-model="selectedOptions"
                @change="setUseInfoSocial"
                :placeholder="addresInfo"
                style="width: 48.5%;"
              >
              </el-cascader>
              —
              <el-input
                v-model="comInfo.address"
                placeholder="街道门牌号"
                style="width:48.5%"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="业务范围"
              :rules="[
                {
                  required: true,
                  message: '请选择业务范围',
                  trigger: 'change',
                },
              ]"
            >
              <el-radio-group v-model="busList" @change="changeBusList">
                <el-radio label="兼职服务">兼职服务</el-radio>
                <el-radio label="培训">培训</el-radio>
                <el-radio label="资质代办">资质代办</el-radio>
                <el-radio label="其他">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="擅长领域"
              v-if="goodAtShow"
              :rules="[
                {
                  required: true,
                  message: '请选择擅长领域',
                  trigger: 'change',
                },
              ]"
            >
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <el-checkbox-group
                v-model="goodAtList"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="(item, index) in goodAtSel"
                  :key="index"
                  :label="item"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="个人介绍">
              <el-input
                type="textarea"
                :rows="3"
                v-model="comInfo.introduction"
              ></el-input>
            </el-form-item>
            <!-- 没数据，首次完善信息 -->
            <el-form-item class="text-center" v-show="!haveDataBtn">
              <el-button
                type="primary"
                class="radius-25"
                style="width: 280px"
                v-prevent-repeat-click
                @click="infoPost"
              >
                提交
              </el-button>
            </el-form-item>
            <!-- 有数据，再次编辑信息 -->
            <el-form-item class="text-center" v-show="haveDataBtn">
              <el-button
                type="primary"
                class="radius-25"
                style="width: 280px"
                v-prevent-repeat-click
                @click="editInfoPost"
              >
                提交编辑信息
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      comInfo: {},
      avatarData: {},
      selectedOptions: [],
      imageUrl: "",
      goodAtShow: true,
      haveDataBtn: false,
      checkAll: false,
      isIndeterminate: false,
      userInfoSocial: provinceAndCityData,
      goodAtList: [],
      busList: "",
      goodAtSel: [
        "一级建造师",
        "二级建造师",
        "注册建造师",
        "注册结构师",
        "公用设备工程师",
        "注册监理工程师",
        "注册造价工程师",
        "注册咨询工程师",
        "职称证书",
        "技工证",
        "其他证书",
      ],
      goodat: [
        "施工总承包",
        "施工专业承包",
        "劳务资质",
        "监理",
        "勘察设计",
        "招标代理",
        "造价咨询",
        "质量检测",
        "审图机构",
        "园林绿化",
        "设计施工一体化",
        "其它",
      ],
      partTimeServe: [
        "一级建造师",
        "二级建造师",
        "注册建造师",
        "注册结构师",
        "公用设备工程师",
        "注册监理工程师",
        "注册造价工程师",
        "注册咨询工程师",
        "职称证书",
        "技工证",
        "其他证书",
      ],
      addresInfo: "",
    };
  },
  computed: {
    ...mapState(["uploadSrc", "userInfo", "Authorization"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    headers() {
      return {
        Accept: "application/json",
        token: this.Authorization,
      };
    },
  },
  methods: {
    getUploadUrl() {
      this.avatarData.fi_objid = this.userInfo.uid;
      this.avatarData.fi_objcat = "at";
      this.avatarData.act = "upload";
    },
    handleAvatarSuccess(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.comInfo.u_portrait = res.resultData.file_id;
    },
    beforeAvatarUpload(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJP) {
        this.$message.error("上传头像图片只能是 JPG 格式或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isJP && isLt2M;
    },
    // 用户信息编辑社保地区
    setUseInfoSocial() {
      let city =
        CodeToText[this.selectedOptions[0]] +
        CodeToText[this.selectedOptions[1]];
      this.comInfo.city = city;
    },
    //  业务范围
    changeBusList(e) {
      if (e == "资质代办") {
        this.goodAtSel = this.goodat;
      } else if (e == "其他") {
        this.goodAtSel = this.goodat;
      } else if (e == "培训") {
        this.goodAtSel = this.partTimeServe;
      } else if (e == "兼职服务") {
        this.goodAtSel = this.partTimeServe;
      }
    },
    handleCheckAllChange(val) {
      this.goodAtList = val ? this.goodAtSel : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.goodAtSel.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.goodAtSel.length;
    },
    // 提交
    infoPost() {
      if (!this.comInfo.birth) {
        this.$message.warning("请填写您的出生年月");
        return;
      }
      this.comInfo.business_scope = this.busList;
      this.comInfo.expert_in = this.goodAtList.toString();
      this.$http.post("/company/user/personal", this.comInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success("已保存编辑信息,2秒后跳转企业首页！");
          setTimeout(() => {
            this.$router.push("/comPage/index");
          }, 1600);
        }
      });
    },
    // 编辑提交
    editInfoPost() {
      if (!this.comInfo.birth) {
        this.$message.warning("请填写您的出生年月");
        return;
      }
      this.comInfo.business_scope = this.busList;
      this.comInfo.expert_in = this.goodAtList.toString();
      this.comInfo.uid = this.userInfo.uid;
      this.$http.post("/company/user/personal", this.comInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success("已保存编辑信息,2秒后跳转企业首页！");
          setTimeout(() => {
            this.$router.push("/comPage/index");
          }, 1600);
        }
      });
    },
    // 判断认证
    getReale() {
      this.$http
        .post("company/index/complete", { com_id: this.comRealeInfo.id })
        .then((res) => {
          if (res.data.resultCode === 1) {
            console.log(res.data.resultData);
            if (res.data.resultData.flag) {
              this.getHomeInfo(1);
            } else {
              this.getHomeInfo(0);
            }
          }
        });
    },
    // 企业信息
    getHomeInfo(reale) {
      let info = {
        com_auth: reale,
        com_id: this.comRealeInfo.id,
        uid: this.userInfo.uid,
      };
      this.$http.post("company/user/headhunt", info).then((res) => {
        if (res.data.resultCode === 1) {
          if (res.data.resultData.length == 0) {
            return;
          }
          this.staffInfo = res.data.resultData[0];
          this.comBaseInfo = res.data.resultData[0].com_info[0];
        }
      });
    },
    // 获取企业下的猎头信息
    getpersonal() {
      this.$http.post("company/user/getpersonal").then((res) => {
        if (res.data.resultCode === 1) {
          if (res.data.resultData.u_name != undefined) {
            this.haveDataBtn = true; // 个人信息已有数据
          }
          this.comInfo = res.data.resultData;
          let data = res.data.resultData;
          this.comInfo.u_sex = data.u_sex + "";
          this.addresInfo = data.city;
          if (data.u_portrait_url != "") {
            this.imageUrl = data.u_portrait_url;
          }
          if (data.business_scope != "" && data.business_scope != undefined) {
            this.busList = data.business_scope;
            this.changeBusList(data.business_scope);
          }
          if (data.expert_in != "" && data.expert_in != undefined) {
            this.goodAtList = data.expert_in.split(",");
            this.isIndeterminate =
              this.goodAtList.length != this.goodAtSel.length;
            this.checkAll = this.goodAtList.length == this.goodAtSel.length;
          }
        }
      });
    },
  },
  mounted() {
    this.getUploadUrl();
    this.getpersonal();
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.comForm {
  margin: 0 100px;
}
.cardTitle {
  font-size: 17px;
  font-weight: bold;
  color: #2674fc;
}
</style>
